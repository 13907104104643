import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../Context/AppContext";
import * as API from "../../Services/APIService";
import formBg from "../../assets/images/form_bg.jpg";
import liffHelper from "../../Utils/liffHelper";

import {
  Button,
  Radio,
  Image,
  Space,
  Popconfirm,
  message,
  Input,
  Upload,
  Select,
  Tag,
} from "antd";
import {
  CameraOutlined,
  CloudUploadOutlined,
  PictureOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import {
  Camera,
  CameraResultType,
  CameraDirection,
  CameraSource,
} from "@capacitor/camera";

import { compressBase64Image } from "../../Utils/CompressImage";

import * as _ from "lodash";
import * as moment from "moment";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import mime from "mime-types";
import imageCompression from "browser-image-compression";
import "./EngiFixReport.scss";

export default function EngiFixReport(props) {
  const { updateTime, setUpdateTime, empProfile, setEmpProfile } =
    useContext(AppContext);

  const [engiFixList, setEngiFixList] = useState([]); // 改善清單 by 監工

  const [pickedFixUUID, setPickedFixUUID] = useState(undefined); // 所選到的改善 uuid
  const [photoList, setPhotoList] = useState([]); // 相片清單
  const [isUploading, setIsUploading] = useState(false); // 是否正在上傳
  const [remark, setRemark] = useState(undefined); // 註解

  const [isFileUploading, setIsFileUploading] = useState(false); // 檔案是否正在上傳

  const { TextArea } = Input;
  const { Option } = Select;

  /* ◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤ componentDidMount ◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤ */

  /**
   *
   * componentDidMount
   *
   *
   */
  useEffect(() => {
    // 更新資料
    setUpdateTime(moment().format("YYYY-MM-DD HH:mm:ss.sss"));

    return () => {
      // componentWillUnmount is here!
    };
  }, []);

  /**
   *
   * componentDidMount
   *
   *
   */
  useEffect(() => {
    //emp profile為空,導向login

    if (_.size(empProfile) === 0) {
      // 轉跳  page
      props.history.push("/login");
      return;
    }

    async function fetchData() {
      // 取得改善清單  by 監工 line id
      const getEngiFixRemarkByLineIDRes = await API.getEngiFixRemarkAll(
        empProfile
      );
      if (_.get(getEngiFixRemarkByLineIDRes, "RESULT") === "OK") {
        setEngiFixList(JSON.parse(_.get(getEngiFixRemarkByLineIDRes, "DATA")));
      }
    }

    // 抓資料
    fetchData();

    return () => {
      // componentWillUnmount is here!
    };
  }, [updateTime, empProfile]);

  /**
   *
   *
   * 拍照
   *
   *
   */
  const takePicture = async () => {
    /*
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: true,
        height: 1500,
        width: 1500,
        allowEditing: true,
        resultType: CameraResultType.DataUrl,
        direction: CameraDirection.Rear,
        source: CameraSource.Camera,
      });
      */

    const image = await Camera.getPhoto({
      quality: 90,
      height: 1500,
      width: 1500,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      direction: CameraDirection.Rear,
      source: CameraSource.Camera,
    });

    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    const imageUrl = image.webPath;

    const { webPath, format } = image;

    // file rename  加時間
    const fileTimeStamp = moment().format("_YYYY-MM-DD_HH:mm:ss");

    // 新檔名
    const fileName = uuidv4() + fileTimeStamp + "." + format;

    // 取得 mime type
    const mimeType = mime.lookup(fileName);

    const obj = {
      fileName,
      mimeType,
    };

    //取得簽名上傳檔案URL
    let signedURL = "";
    const generateV4PutObjectSignedUrlRes =
      await API.generateV4PutObjectSignedUrl(obj);

    if (_.get(generateV4PutObjectSignedUrlRes, "RESULT") === "OK") {
      signedURL = _.get(generateV4PutObjectSignedUrlRes, "URL");
    }

    //壓縮圖片
    // const compressImage = await compressBase64Image(
    //   image.dataUrl,
    //   0.68,
    //   image.format
    // );

    // 轉 blob
    let blob = await fetch(webPath).then((r) => r.blob());

    //壓縮圖片
    try {
      //壓縮圖片 options
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1500,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(blob, options);

      //壓縮完成
      // 使用 FileReader 轉換成 binary file
      const reader = new FileReader();
      reader.readAsArrayBuffer(compressedFile);
      let fileData = null;

      reader.onload = (e) => {
        // 在文件讀取完成
        fileData = e.target.result;

        // 使用 axios 上傳
        axios
          .put(signedURL, fileData, {
            headers: {
              "Content-Type": mimeType,
            },
          })
          .then(async (response) => {
            // 上傳完成 , 調整 response
            let url = _.split(response.config.url, "?", 1)[0];

            response.url = url;
            response.fileName = fileName;

            // 更新 photo list
            setPhotoList([...photoList, response]);
          })
          .catch();
      };
    } catch (error) {
      console.log(error);
    }
  };

  /**
   *
   * 刪除 相片
   *
   *
   */
  const onImageDelete = async (_fileLink, _fileName) => {
    setPhotoList(_.filter(photoList, (item) => item.fileName != _fileName));

    //稽核軌跡 刪除
    const obj = {
      porgName: "工程改善通知彙報",
      page: "工程改善通知彙報",
      action: "delete",
      empId: empProfile.empId,
      fileName: _fileName,
      fileLink: _fileLink,
      userCreate: empProfile.empId,
    };

    await API.addAuditTrail(obj);
  };
  /**
   *
   * 上傳
   *
   *
   */
  const onSubmit = async () => {
    // 欄位檢查
    if (pickedFixUUID === undefined) {
      message.error("請選擇改善通知!");
      return;
    }

    if (_.size(photoList) === 0) {
      message.error("請新增施工照片  !");
      return;
    }

    const obj = {
      uuid: pickedFixUUID,
      deptDesc: "ENGI",
      photoList,
      userCreate: empProfile.empId,
    };

    const addFixResultRes = await API.addFixResult(obj);

    if (_.get(addFixResultRes, "RESULT") === "OK") {
      message.success("新增成功!");

      //清空資料
      setPhotoList([]);

      //上傳結束
      setIsUploading(false);
    } else {
      //上傳結束
      setIsUploading(false);
      message.error("新增失敗!");
    }
  };

  /**
   *
   * 自定義上傳
   *
   *
   */
  const customRequest = async (_req) => {
    const {
      action,
      file,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials,
    } = _req;

    // 自定義上傳
    //https://www.bbsmax.com/A/pRdBkjoGzn/
    // file rename  加時間
    const fileTimeStamp = moment().format("_YYYY-MM-DD_HH:mm:ss");

    const { name } = file;

    // 檔名
    const originFileName = name.substring(0, name.lastIndexOf("."));
    // 副檔名
    const fileExtensionName = name.split(".").pop();

    // 取得 mime type
    const mimeType = mime.lookup(name);

    // 新檔名
    const fileName = `${originFileName}${fileTimeStamp}.${fileExtensionName}`;

    const obj = {
      fileName,
      mimeType,
    };

    //取得簽名上傳檔案URL
    let signedURL = "";
    const generateV4PutObjectSignedUrlRes =
      await API.generateV4PutObjectSignedUrl(obj);

    if (_.get(generateV4PutObjectSignedUrlRes, "RESULT") === "OK") {
      signedURL = _.get(generateV4PutObjectSignedUrlRes, "URL");
    }

    //壓縮圖片
    try {
      //壓縮圖片 options
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1500,
        useWebWorker: true,
      };

      let compressedFile = await imageCompression(file, options);

      const compressedFileSizeKb = _.floor(
        (_.get(compressedFile, "size") || 0) / 1024
      );

      // 如果壓縮後小於 100 kb ==> 再壓縮一次 for black image
      if (compressedFileSizeKb < 100) {
        compressedFile = await imageCompression(file, options);
      }

      //壓縮完成
      // 使用 FileReader 轉換成 binary file
      const reader = new FileReader();
      reader.readAsArrayBuffer(compressedFile);
      let fileData = null;

      reader.onload = (e) => {
        // 在文件讀取完成
        fileData = e.target.result;
        // 使用 axios 上傳
        axios
          .put(signedURL, fileData, {
            headers: {
              "Content-Type": mimeType,
            },
            onUploadProgress: ({ total, loaded }) => {
              //上傳進度輸出
              onProgress(
                {
                  percent: Math.round((loaded / total) * 100).toFixed(2),
                },
                file
              );
            },
          })
          .then(async (response) => {
            // 上傳完成 , 調整 response

            let url = _.split(response.config.url, "?", 1)[0];

            response.url = url;
            response.name = decodeURI(url.substring(url.lastIndexOf("/") + 1));

            response.fileName = decodeURI(
              url.substring(url.lastIndexOf("/") + 1)
            );

            // 更新 photo list
            //   setPhotoList([...photoList, response]);

            onSuccess(response, file);

            //稽核軌跡 上傳
            const { fileName, fileLink } = response;

            const obj = {
              porgName: "工程改善通知彙報",
              page: "工程改善通知彙報",
              action: "upload",
              empId: empProfile.empId,
              fileName: fileName,
              fileLink: fileLink,
              userCreate: empProfile.empId,
            };

            await API.addAuditTrail(obj);
          })
          .catch(onError);
      };
      return {
        abort() {
          console.log("upload progress is aborted.");
        },
      };
    } catch (error) {
      console.log(error);
    }
  };
  /**
   *
   * 上傳 file 改變事件
   *
   */
  const onFileUploadChange = (_info) => {
    const { file, fileList } = _info;

    const newFileList = _.cloneDeep(fileList);

    _.forEach(newFileList, (item) => {
      item.key = "529c295b_" + item.uid;

      if (_.get(item, "response") !== undefined) {
        item.url = item.response.url;
        item.name = item.response.name;
        item.fileName = item.response.fileName;
      }
    });

    // 還沒上傳的檔案
    const notUploadObj = _.find(fileList, (item) => item.percent !== 100);

    if (_.size(notUploadObj)) {
      // 有 沒上傳的檔案
      setIsFileUploading(true);
    } else {
      // 上傳完了
      setIsFileUploading(false);
    }

    setPhotoList(newFileList);
  };

  /**
   *
   *
   * 上傳前-限制檔案
   *
   *
   */
  const beforeUpload = (_file) => {
    const isPNG = _file.type === "image/png" || _file.type === "image/jpeg";
    if (!isPNG) {
      message.error("只支援 JPG 或 PNG 圖片");
    }
    return isPNG || Upload.LIST_IGNORE;
  };
  /* ◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤ JSX ◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤ */

  return (
    <div className="daily-report-comp">
      {/* 背景圖檔 */}
      <div className="bg-image">
        {" "}
        <img src={formBg} alt="" />
      </div>
      {/* header */}
      <div className="header">
        <div className="title">CIRSOLAR</div>
        <div className="sub-title">工程改善通知彙報</div>
      </div>

      {/* form area */}
      <div className="form-area">
        <div className="input-area">
          {/* 選擇案場 */}
          <div className="label">選擇改善通知</div>
          <div className="input-field">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                const { children } = option;

                return _.includes(_.toString(children), input);
              }}
              filterSort={(optionA, optionB) => 1}
              onChange={(e) => setPickedFixUUID(e)}
              value={pickedFixUUID}
              style={{ width: "100%" }}
            >
              {engiFixList.map((item, index) => {
                return (
                  <Option value={item.uuid} key={item.key}>
                    {_.size(item.siteMgrEmpName) > 0 ? (
                      <Tag color="blue">{`${item.siteMgrEmpName}`}</Tag>
                    ) : (
                      ``
                    )}{" "}
                    {item.fixRemark}
                  </Option>
                );
              })}
            </Select>
          </div>

          {/* 施工照片 */}
          <div className="label">施工照片</div>
          <div className="input-field">
            {/* 拍照區 */}
            <div className="photo-field">
              {/* 按鈕區 */}
              <div className="btn-area">
                {/* 多選上傳 */}
                <Upload
                  name="file"
                  headers={{
                    "Content-Type": "application/octet-stream",
                  }}
                  customRequest={(req) => customRequest(req)}
                  onChange={(info) => onFileUploadChange(info)}
                  beforeUpload={(file) => beforeUpload(file)}
                  fileList={photoList}
                  multiple={true}
                  showUploadList={false}
                >
                  <Button
                    type="primary"
                    shape="round"
                    icon={<PictureOutlined />}
                  >
                    拍照
                  </Button>
                </Upload>

                {/* <Button
                  type="primary"
                  shape="round"
                  icon={<CameraOutlined />}
                  onClick={() => takePicture()}
                >
                  拍照
                </Button> */}
              </div>

              {/* 照片顯示區 */}
              <div className="image-area">
                {_.size(photoList) > 0 && (
                  <Image.PreviewGroup>
                    {photoList.map((item) => (
                      <div className="image-container" key={item.key}>
                        <Image src={item.url}></Image>
                        <Popconfirm
                          title="確認刪除 ?"
                          onConfirm={() =>
                            onImageDelete(item.url, item.fileName)
                          }
                          okText="是"
                          cancelText="否"
                        >
                          <Button type="primary" size="small" danger>
                            刪除
                          </Button>
                        </Popconfirm>
                      </div>
                    ))}
                  </Image.PreviewGroup>
                )}
              </div>

              {/* 圖片上傳中 */}
              {isFileUploading && (
                <div className="input-field">
                  <Button block danger>
                    圖片上傳中...
                  </Button>
                </div>
              )}
            </div>
          </div>

          {/* 送出按鈕 */}
          <div className="label"> </div>
          <div className="input-field">
            <Button
              type="primary"
              block
              icon={<CloudUploadOutlined />}
              onClick={() => onSubmit()}
              loading={isUploading}
              disabled={isFileUploading}
            >
              上傳
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
