import Login from "./components/Login/Login.jsx";
import EngiFixReport from "./components/EngiFixReport/EngiFixReport";

const rootPath = process.env.PUBLIC_URL;

//  拿掉  exact 避免 LIFF 白畫面
const routes = [
  {
    path: `/login`,
    component: Login,
  },

  {
    path: `/engi-fix`,
    component: EngiFixReport,
  },
  {
    path: `/*`,
    component: Login,
  },
];

export default routes;
export { rootPath };
