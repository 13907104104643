import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../Context/AppContext";
import { getEmpInfoByLineId, initDB } from "../../Services/APIService";

import { QRCode } from "react-qrcode-logo";
import loginBg from "../../assets/images/login_bg.jpg";

import liffHelper from "../../Utils/liffHelper";
import { encodeStr, decodeStr } from "../../Utils/Encypt";

import { Spin, message } from "antd";
import * as _ from "lodash";
import * as moment from "moment";
import "./Login.scss";

export default function Login(props) {
  const { updateTime, setUpdateTime, empProfile, setEmpProfile } =
    useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false); //正在載入指示

  /**
   *
   * componentDidMount
   *
   *
   */
  useEffect(() => {
    console.log("==> loginc comp componentDidMount");

    async function fetchData() {
      // INIT DB
      await initDB();

      // 取得 Line 使用者資訊
      let profile = {};

      if (process.env.REACT_APP_ENV === "dev") {
        //開發環境
        /**************************** 測試用 ****************************/
        profile = {
          displayName: "傑",
          pictureUrl:
            "https://profile.line-scdn.net/0hYTQnjl-SBnt8FyyjMx14BAxHBRFfZl9pWSNIHk0fW09ALkQvVnhKT01CUB8VIkN-VXVOFElEWBlwBHEdYkH6T3snWExFIEcoWHlBlQ",
          statusMessage: "(alien face)",
          userId: "Ub55b55bc040a281efffb701494ac9d6e",
        };
        /**************************** 測試用 ****************************/
        // profile = await liffHelper.getUserProfile();
      } else {
        //正式環境,測試環境
        profile = await liffHelper.getUserProfile();
      }

      console.log("===> Line profile");

      console.log(profile);

      //檢查是否已綁定
      const checlkBindedResult = await getEmpInfoByLineId({
        lineId: profile.userId,
      });

      if (_.get(checlkBindedResult, "RESULT") === "OK") {
        //已綁定
        message.success("登入成功!");
        setIsLoading(false);

        const empData = JSON.parse(_.get(checlkBindedResult, "DATA"));

        // 塞 local storage
        const obj = {
          date: moment().format("YYYY-MM-DD"),
          ...empData,
        };

        localStorage.setItem("CIRSOLAR_TOKEN", encodeStr(JSON.stringify(obj)));

        //更新員工資料
        setEmpProfile({ ...empData, ...profile });

        //更新 update time
        setUpdateTime(moment().format("YYYY-MM-DD HH:mm:ss.sss"));

        // 轉跳  page
        props.history.push("/engi-fix");

        return;
      }
      setIsLoading(false);
      message.success(`${_.get(profile, "displayName")} 請綁定工號!`);
    }

    /*
    // cirsolar TOKEN
    const cirsolarTokenStr = localStorage.getItem("CIRSOLAR_TOKEN");
    if (_.size(cirsolarTokenStr) === 0) {
      //沒有 TOKEN
      // do fetchData (取得 Line 使用者資訊)
      console.log("沒有 TOKEN => do fetchData (取得 Line 使用者資訊)");
      setIsLoading(true);
      fetchData();
      setIsLoading(false);
    } else {
      // 有 TOKEN 檢查登入日期
      const cirsolarTokenObj = JSON.parse(decodeStr(cirsolarTokenStr));

      console.log("cirsolarTokenObj");
      console.log(cirsolarTokenObj);

      if (
        _.size(cirsolarTokenObj) > 0 &&
        _.get(cirsolarTokenObj, "date") === moment().format("YYYY-MM-DD")
      ) {
        // 直接登入
        console.log("直接登入");

        message.success("登入成功!");

        setEmpProfile(cirsolarTokenObj);

        //更新 update time
        setUpdateTime(moment().format("YYYY-MM-DD HH:mm:ss.sss"));

        // 轉跳 main menu page
        props.history.push("/daily-report");
      } else {
        // do fetchData (取得 Line 使用者資訊)
        console.log("登入過期 => do fetchData (取得 Line 使用者資訊)");
        setIsLoading(true);
        fetchData();
        setIsLoading(false);
      }
    }
    */
    setIsLoading(true);
    fetchData();
    setIsLoading(false);

    return () => {
      // componentWillUnmount is here!
    };
  }, []);

  return (
    <div className="login-comp">
      {/* 背景圖檔 */}
      <div className="bg-image">
        {" "}
        <img src={loginBg} alt="" />
      </div>

      <Spin tip="處理中..." spinning={isLoading}>
        {/* CIRSOLAR */}
        <div className="cir-area">
          <h1>CIRSOLAR</h1>
        </div>

        {/* QR Code Area */}
        <div className="qr-code-area">
          <QRCode
            value={process.env.REACT_APP_LINE_AT_LINK}
            eyeRadius={1}
            fgColor="#36cfc9"
          />
        </div>

        {/* 提醒訊息 */}
        <div className="notice-message-area">綁定工號後重新整理登入使用</div>
      </Spin>
    </div>
  );
}
