import * as axios from "axios";

/**
 *
 * APIURL
 *
 *
 */
const getAPIURL = () => {
  return `${process.env.REACT_APP_API}`;
};

/**
 *
 * 取得員工資料 by Line Id
 *
 */
const getEmpInfoByLineId = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/get/emp/bylineid`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 * 取得人員清單 by 部門
 *
 */
const getEmpListByDeptName = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/get/emp/byDeptName`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 * INIT DB
 *
 */
const initDB = async () => {
  const api = getAPIURL();
  const URL = `${api}/api/init/DB`;

  try {
    const response = await axios.get(URL);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 *  取得改善通知內容 by 監工 line  id
 *
 */
export const getEngiFixRemarkByLineID = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/get/engifix/list/by-lineid`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 *  新增 改善結果
 *
 */
export const addFixResult = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/add/fixresult`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 * 取得簽名上傳檔案URL
 *
 */
export const generateV4PutObjectSignedUrl = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/docs/get/upload/signurl`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 *   取得改善通知內容 ALL
 *
 */
export const getEngiFixRemarkAll = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/get/engifix/list/all/by-lineid`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 *  新增 稽核軌跡紀錄
 *
 */
const addAuditTrail = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/add/audit-trail`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

export { getEmpInfoByLineId, getEmpListByDeptName, initDB, addAuditTrail };
